<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" id="preview" class="mb-3"></b-col>
          </b-row>
          <div slot="header">
            <strong>{{ staticNames.name }}&nbsp;Banner Image By Movie</strong>
            <small>Form</small>
          </div>
          <b-form v-on:submit.prevent.self="submitBannerByMovie">
            <b-row>
              <b-col sm="4">
                <b-form-group>
                  <v-select
                    :options="countryList"
                    :clearable="false"
                    v-model="tempSelectedCountry"
                    :close-on-select="true"
                    :reduce="country => country.CountryID"
                    label="CountryName"
                    @input="onChangeCountryDropdwon($event)"
                    class="v-select-cust CustomVSelect Movies-V-select"
                    placeholder="Search Country Name..."
                  >
                    <template slot="option" slot-scope="option">{{ option.CountryName }}</template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <!-- <b-form-select v-model="banner.selectedMovieBanner" id="movie"
                                    :plain="true"
                                    value-field="MovieID"
                                    text-field="Title"
                                    :options=moviesList>
                                        <template slot="first">
                                            <option :value="null" disabled>-- Please select Movie --</option>
                                        </template>
                  </b-form-select>-->
                  <v-select
                    :options="moviesList"
                    :clearable="false"
                    v-model="tempSelectedMovie"
                    :close-on-select="true"
                    :reduce="movie => movie.MovieID"
                    label="Title"
                    @input="onChangeMovieDropdwon($event)"
                    class="v-select-cust CustomVSelect Movies-V-select"
                    placeholder="Search Movie Name..."
                  >
                    <template slot="option" slot-scope="option">{{ option.Title }}</template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <b-form-select
                    required
                    v-model="banner.BannerType"
                    :plain="true"
                    :options="['Home', 'Movie', 'Static']"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <c-switch
                  class="mx-1"
                  color="primary"
                  v-model="banner.BannerIsActive"
                  variant="3d"
                  label
                  v-bind="{dataOn: '\u2713',dataOff: '\u2715'}"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="4">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ staticNames.name }}
                </b-button>
              </b-col>
              <b-col sm="4">
                <b-button @click="resetMovieBanner" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";
import CinemaService from "@/services/CinemaService";

export default {
  data() {
    return {
      staticNames: {
        name: "Add"
      },
      moviesList: [],
      countryList: [],
      tempSelectedCountry: null,
      tempSelectedMovie: null,
      banner: {
        BannerType: "Home",
        CountryID: null,
        bannerId: null,
        selectedMovieBanner: null,
        flag: 1,
        BannerIsActive: true
      }
    };
  },
  components: {
    cSwitch
  },
  computed: {},
  watch: {},
  beforeMount() {
    // this.getMoviesList();

    const { movieId, bannerId, countryId } = this.$route.query;
    if (movieId && bannerId && countryId) {
      this.banner.flag = 2;
      this.banner.selectedMovieBanner = String(movieId);
      this.staticNames.name = "Update";
      this.banner.bannerId = bannerId;
      // banner.BannerType
      // getBannerByID
      this.getBannersDetails(bannerId, countryId);
    } else {
      this.getCountryList(null);
    }
  },
  mounted: function() {},
  methods: {
    getBannersDetails: function(BannerID, CountryID) {
      let payload = {
        BannerID,
        CountryID
      };

      MasterService.getBannerByID(payload)
        .then(response => {
          const { data } = response;
          console.log("response", response);
          if (data.Status && data.BannerList.length > 0) {
            this.banner.CountryID = data.BannerList[0].CountryID;
            this.banner.BannerType = data.BannerList[0].BannerType || "Home";
            this.banner.selectedMovieBanner = data.BannerList[0].MovieID;
            this.getCountryList(CountryID);
            this.getMoviesList(data.BannerList[0].MovieID);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onChangeMovieDropdwon: function(event) {
      this.banner.selectedMovieBanner = event.MovieID;
    },
    onChangeCountryDropdwon: function(event) {
      this.banner.CountryID = event.CountryID;
      if (this.banner.CountryID) {
        this.getMoviesList(this.banner.selectedMovieBanner);
      }
    },
    getCountryList: function(CountryID) {
      this.cinemasList = [];
      MasterService.getActiveCountries()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countryList = data.CountryList;
            this.countryList.map(x => {
              if (String(CountryID) == String(x.CountryID)) {
                this.tempSelectedCountry = x;
              }
            });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on GetCinemas Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getMoviesList: function(MovieID) {
      this.moviesList = [];
      // MovieService.getMoviesDDL()
      MovieService.getActiveShows({ CountryID: this.banner.CountryID })
        .then(response => {
          const { data } = response;
          console.log("data :", data);
          if (data.Status && data.MovieList.length > 0) {
            this.moviesList = data.MovieList;
            this.moviesList.map(x => {
              if (String(MovieID) == String(x.MovieID)) {
                this.tempSelectedMovie = x;
              }
            });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on Banner-getMoviesDDL Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetMovieBanner: function() {
      this.banner.selectedMovieBanner = null;
      this.banner.BannerIsActive = true;
      this.banner.flag = 1;
      this.banner.BannerType = "Home";
      this.banner.bannerId = null;
      this.banner.CountryID = null;
      window.location.replace(
        // window.location.origin + "/" + window.location.hash.split("?")[0]
        window.location.href.split("?")[0]
      );
    },
    submitBannerByMovie: function() {
      if (this.banner.selectedMovieBanner == null) {
        this.showSubmitConfirmation("Please Select Any Banner");
      } else {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");

      let payload = {
        CountryID: this.banner.CountryID || "",
        Flag: this.banner.flag,
        MovieID: this.banner.selectedMovieBanner,
        BannerID: this.banner.bannerId,
        BannerIsActive: this.banner.BannerIsActive,
        BannerType: this.banner.BannerType
      };
      MasterService.addBanner(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.banner.selectedMovieBanner = null;
            this.banner.BannerIsActive = true;
            this.banner.flag = 1;
            this.banner.BannerType = "Home";
            this.banner.bannerId = null;
            this.banner.CountryID = null;
            this.$router.push({
              path: "/banners/bannerlist"
            });
          }
        })
        .catch(error => {
          console.log("Catch on Banner-addBanner Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  },
  updated() {
    // .then(response => {
    //       const { data } = response;
    //       if (data.Status && data.Userrole.length > 0) {
    //       } else {
    //         this.showMessageSnackbar(data.Message);
    //       }
    //     })
    //     .catch(error => {
    //       console.log("Catch on AddUser-getRoles Error: ", error);
    //       this.showMessageSnackbar(
    //         "Oops! Something went wrong. kindly contact your administrator"
    //       );
    //     });
  }
};
</script>

<style>
</style>
